import { ChangeDataArgs } from '@samc/react-ui-grid';
import React from 'react';
import { useCurrentUser } from '../../../features/authorization';
import ExtendedGridFieldConfiguration from '../../../types/ExtendedGridFieldConfiguration';
import { ControlBar } from '../../Common';
import {
  Params,
  BtnCellRenderer,
  BtnCellRendererActions,
  EditableRenderer,
  ProductCellRenderer,
} from './config';
import { Container, GridWrapper } from './styles';
import { useUpdateOrderIdentifier } from '../../../mutations/useUpdateOrderIdentifier';
import { OrderTrackingData } from '../../../queries/useOrders';
import { queryClient } from '../../../services/queryClient';
import { useAgGridApi } from '../../../features/authorization/hooks/useAgGridApi';

const Grid = React.lazy(() => import('../../Common/Grid'));

export interface DownloadResultsParams {
  loan?: AppraisalDownload;
}

interface AppraisalDownload {
  orderId: string;
  loanId: string;
}
export interface OrdersGridProps {
  fields: ExtendedGridFieldConfiguration[];
  orders: OrderTrackingData[];
  setSelectedOrder: (order: OrderTrackingData) => void;
  onActiveRow: (orderId: string) => void;
  activeRow?: string;
  highlightedRow?: string;
  loanSearchText?: string;
  setLoanSearchText?: (text: string) => void;
}

function OrdersGrid({
  fields,
  orders,
  activeRow,
  setSelectedOrder,
  highlightedRow,
  onActiveRow,
  loanSearchText,
  setLoanSearchText,
}: OrdersGridProps) {
  const [gridApi, handleGridReady] = useAgGridApi<OrderTrackingData>();
  const currentUser = useCurrentUser();
  const updateOrderIdentifierMutation = useUpdateOrderIdentifier();

  const onChangeData = React.useCallback(
    (args: ChangeDataArgs) => {
      const { orderId, orderIdentifier } = args.record as OrderTrackingData;

      updateOrderIdentifierMutation.mutate(
        { orderId, orderIdentifier },
        {
          onSuccess: () => {
            if (gridApi) {
              gridApi.forEachNode(node => {
                const nodeData = node.data as OrderTrackingData;
                if (nodeData.orderId === orderId) {
                  gridApi.flashCells({
                    rowNodes: [node],
                    columns: ['orderIdentifier'],
                  });
                }
              });
            }

            queryClient.invalidateQueries(['bpoPanelOrder', orderId]);
          },
        },
      );
    },
    [gridApi, updateOrderIdentifierMutation],
  );

  const hasBpoOrderDetailsEntitlement = React.useMemo(
    () => currentUser.hasEntitlement('Order Tracking - BPO Order Details'),
    [currentUser],
  );

  const onChangeSelected = React.useCallback(
    (params: unknown) => {
      const { data } = params as Params;
      if (
        data.productName === 'Broker Price Opinion' &&
        hasBpoOrderDetailsEntitlement
      ) {
        setSelectedOrder(data);
      }
      onActiveRow(data.orderId);
    },
    [hasBpoOrderDetailsEntitlement, onActiveRow, setSelectedOrder],
  );

  const onGridExport = React.useCallback(() => {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        processCellCallback: params => {
          let value = params.value;
          if (typeof value === 'string' && /^[+=@\t\r-]/.test(value)) {
            value = `'${value}`;
          }
          return value;
        },
      });
    }
  }, [gridApi]);

  React.useEffect(() => {
    if (gridApi) {
      gridApi.forEachNode(node => {
        node.setSelected(node.data ? node.data.orderId === activeRow : false);
      });
    }
  }, [activeRow, gridApi]);

  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const frameworkComponents = React.useMemo(
    () => ({
      BtnCellRenderer,
      BtnCellRendererActions,
      EditableRenderer,
      ProductCellRenderer,
    }),
    [],
  );

  const rowClassRules = React.useMemo(
    () => ({
      'highlight-row': (params: unknown) => {
        const { data } = params as Params;
        return data.orderId === highlightedRow;
      },
      'clickable-row': (params: unknown) => {
        const { data } = params as Params;
        return data.productName === 'Broker Price Opinion';
      },
    }),
    [highlightedRow],
  );

  const gridOptions = React.useMemo(
    () => ({
      onRowDoubleClicked: onChangeSelected,
    }),
    [onChangeSelected],
  );

  return (
    <Container>
      <ControlBar
        onExport={onGridExport}
        loanSearchText={loanSearchText}
        setLoanSearchText={setLoanSearchText}
      />
      <GridWrapper id="order-grid">
        <Grid
          onGridReady={handleGridReady}
          onChangeData={onChangeData}
          data={orders}
          fields={fields}
          frameworkComponents={frameworkComponents}
          rowClassRules={rowClassRules}
          rowsPerPage={100}
          gridOptions={gridOptions}
          suppressPaginationPanel={false}
          suppressRowSelector
        />
      </GridWrapper>
    </Container>
  );
}

export default OrdersGrid;

import * as React from 'react';
import { BaseGrid, BaseGridRef, ChangeDataArgs } from '@samc/react-ui-grid';
import ExtendedGridFieldConfiguration from '../../../types/ExtendedGridFieldConfiguration';
import { Container, GridWrapper } from './styles';
import {
  BtnCellRenderer,
  CheckboxCellRenderer,
  EditableRenderer,
  StatusCellRederer,
} from './config';
import { OrderTrackingLoan } from '../../../context/order/appraisal/types';
import { ControlBar } from '../../Common';
import { useAgGridApi } from '../../../features/authorization/hooks/useAgGridApi';

export interface BPOGridProps {
  onSelectionChanged?: (
    loan: OrderTrackingLoan | OrderTrackingLoan[] | null,
    checked: boolean,
  ) => void;
  fields: ExtendedGridFieldConfiguration[];
  loans?: OrderTrackingLoan[];
  selectedLoans: OrderTrackingLoan[];
  highlightRows?: string[];
  onChangeData?: (args: ChangeDataArgs<OrderTrackingLoan>) => void;
}

function BPOGrid({
  fields,
  loans,
  onSelectionChanged,
  selectedLoans,
  highlightRows,
  onChangeData,
}: BPOGridProps) {
  const [gridApi, onGridReady] = useAgGridApi<OrderTrackingLoan>();

  React.useEffect(() => {
    if (gridApi && Object.keys(gridApi) && selectedLoans.length) {
      gridApi.forEachNode(node => {
        const isSelected = selectedLoans.some(
          loan =>
            node.data !== undefined &&
            loan.orderItemId === node.data.orderItemId,
        );
        node.setSelected(
          node.data !== undefined &&
            node.data.loanId !== undefined &&
            isSelected,
          true,
        );
      });
    }
  }, [gridApi, selectedLoans]);

  const data = React.useMemo(
    () =>
      loans?.map(loan => {
        return {
          id: loan.clarityLoanNumber,
          ...loan,
        };
      }),
    [loans],
  );

  const onExcelExport = React.useCallback(() => {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        processCellCallback: params => {
          let value = params.value;
          if (typeof value === 'string' && /^[+=@\t\r-]/.test(value)) {
            value = `'${value}`;
          }
          return value;
        },
      });
    }
  }, [gridApi]);

  const selectAllRef = React.createRef<HTMLInputElement>();

  const allFields: ExtendedGridFieldConfiguration[] = React.useMemo(
    () => [
      {
        field: 'checkbox',
        cellRenderer: 'CheckboxCellRenderer',
        cellRendererParams: {
          onSelectionChanged: (
            loan: OrderTrackingLoan | null,
            checked: boolean,
          ) => {
            if (onSelectionChanged) {
              onSelectionChanged(loan, checked);
            }

            if (checked === false && selectAllRef.current) {
              selectAllRef.current.checked = false;
            }
          },
          selectedLoans,
        },
        width: 40,
        sortable: false,
        filter: false,
        suppressSizeToFit: true,
        resizable: false,
        valueGetter: () => {
          return 'asdf';
        },
      },
      ...fields,
    ],
    [fields, onSelectionChanged, selectAllRef, selectedLoans],
  );

  const selectAllVisibleRef = React.createRef<HTMLInputElement>();
  const selectAllNotDownloadedExcelRef = React.createRef<HTMLInputElement>();
  const selectAllVisibleNotDownloadedExcelRef =
    React.createRef<HTMLInputElement>();
  const selectAllNotDownloadedPdfRef = React.createRef<HTMLInputElement>();
  const selectAllVisibleNotDownloadedPdfRef =
    React.createRef<HTMLInputElement>();
  const gridRef = React.createRef<BaseGridRef>();

  return (
    <Container>
      <ControlBar onExport={onExcelExport} suppressDeleteButton />
      <GridWrapper>
        <div
          style={{
            marginTop: '-18px',
            flexDirection: 'row',
            display: 'flex',
            position: 'relative',
            zIndex: 1,
            paddingRight: '50px',
          }}
        >
          <div style={{ margin: '0px 5px' }}>
            <input
              type="checkbox"
              onChange={event => {
                if (onSelectionChanged) {
                  onSelectionChanged(
                    event.target.checked ? null : [],
                    event.target.checked,
                  );
                }

                if (event.target.checked && selectAllVisibleRef.current) {
                  selectAllVisibleRef.current.checked = false;
                }
              }}
              ref={selectAllRef}
            />{' '}
            Select All
          </div>
          <div style={{ margin: '0px 5px' }}>
            <input
              type="checkbox"
              onChange={event => {
                const currentPage = gridApi?.paginationGetCurrentPage() || 0;
                const pageSize = gridApi?.paginationGetPageSize() || 100;
                const startIndex = currentPage * pageSize;
                const endIndex = Math.min(
                  startIndex + pageSize - 1,
                  loans ? loans.length : 0,
                );

                const loansOnCurrentPage: OrderTrackingLoan[] = [];
                if (loans && loans.length > 0) {
                  gridApi?.forEachNodeAfterFilterAndSort(node => {
                    if (
                      node &&
                      node.rowIndex !== undefined &&
                      node.rowIndex !== null &&
                      node.rowIndex >= startIndex &&
                      node.rowIndex <= endIndex
                    ) {
                      for (let i = 0; i < loans?.length; i++) {
                        if (
                          node.data !== undefined &&
                          loans[i].loanId === node.data.loanId
                        ) {
                          loansOnCurrentPage.push(loans[i]);
                        }
                      }
                    }
                  });
                }

                if (onSelectionChanged) {
                  onSelectionChanged(
                    loans && event.target.checked ? loansOnCurrentPage : [],
                    event.target.checked,
                  );
                }

                if (event.target.checked && selectAllRef.current) {
                  selectAllRef.current.checked = false;
                }
              }}
              ref={selectAllVisibleRef}
            />{' '}
            Select All on Page
          </div>
          <div style={{ margin: '0px 5px' }}>
            <input
              type="checkbox"
              onChange={event => {
                const notDownloadedLoans: OrderTrackingLoan[] = [];
                if (loans && loans.length > 0) {
                  for (let i = 0; i < loans?.length; i++) {
                    if (!loans[i].lastDownloadedExcelDate) {
                      notDownloadedLoans.push(loans[i]);
                    }
                  }
                }

                if (onSelectionChanged) {
                  onSelectionChanged(
                    loans && event.target.checked ? notDownloadedLoans : [],
                    event.target.checked,
                  );
                }

                if (event.target.checked) {
                  if (selectAllRef.current) {
                    selectAllRef.current.checked = false;
                  }
                  if (selectAllVisibleRef.current) {
                    selectAllVisibleRef.current.checked = false;
                  }
                  if (selectAllNotDownloadedPdfRef.current) {
                    selectAllNotDownloadedPdfRef.current.checked = false;
                  }
                  if (selectAllVisibleNotDownloadedExcelRef.current) {
                    selectAllVisibleNotDownloadedExcelRef.current.checked =
                      false;
                  }
                  if (selectAllVisibleNotDownloadedPdfRef.current) {
                    selectAllVisibleNotDownloadedPdfRef.current.checked = false;
                  }
                }
              }}
              ref={selectAllNotDownloadedExcelRef}
            />{' '}
            Select All Not Downloaded Excel
          </div>
          <div style={{ margin: '0px 5px' }}>
            <input
              type="checkbox"
              onChange={event => {
                const notDownloadedLoans: OrderTrackingLoan[] = [];
                if (loans && loans.length > 0) {
                  for (let i = 0; i < loans?.length; i++) {
                    if (!loans[i].lastDownloadedPdfDate) {
                      notDownloadedLoans.push(loans[i]);
                    }
                  }
                }

                if (onSelectionChanged) {
                  onSelectionChanged(
                    loans && event.target.checked ? notDownloadedLoans : [],
                    event.target.checked,
                  );
                }

                if (event.target.checked) {
                  if (selectAllRef.current) {
                    selectAllRef.current.checked = false;
                  }
                  if (selectAllVisibleRef.current) {
                    selectAllVisibleRef.current.checked = false;
                  }
                  if (selectAllNotDownloadedExcelRef.current) {
                    selectAllNotDownloadedExcelRef.current.checked = false;
                  }
                  if (selectAllVisibleNotDownloadedExcelRef.current) {
                    selectAllVisibleNotDownloadedExcelRef.current.checked =
                      false;
                  }
                  if (selectAllVisibleNotDownloadedPdfRef.current) {
                    selectAllVisibleNotDownloadedPdfRef.current.checked = false;
                  }
                }
              }}
              ref={selectAllNotDownloadedPdfRef}
            />{' '}
            Select All Not Downloaded PDF
          </div>
          <div style={{ margin: '0px 5px' }}>
            <input
              type="checkbox"
              onChange={event => {
                const currentPage = gridApi?.paginationGetCurrentPage() || 0;
                const pageSize = gridApi?.paginationGetPageSize() || 100;
                const startIndex = currentPage * pageSize;
                const endIndex = Math.min(
                  startIndex + pageSize - 1,
                  loans ? loans.length : 0,
                );

                const loansOnCurrentPage: OrderTrackingLoan[] = [];
                if (loans && loans.length > 0) {
                  gridApi?.forEachNodeAfterFilterAndSort(node => {
                    if (
                      node &&
                      node.rowIndex !== undefined &&
                      node.rowIndex !== null &&
                      node.rowIndex >= startIndex &&
                      node.rowIndex <= endIndex
                    ) {
                      for (let i = 0; i < loans?.length; i++) {
                        if (
                          node.data !== undefined &&
                          loans[i].loanId === node.data.loanId &&
                          !loans[i].lastDownloadedExcelDate
                        ) {
                          loansOnCurrentPage.push(loans[i]);
                        }
                      }
                    }
                  });
                }

                if (onSelectionChanged) {
                  onSelectionChanged(
                    loans && event.target.checked ? loansOnCurrentPage : [],
                    event.target.checked,
                  );
                }

                if (event.target.checked && selectAllRef.current) {
                  selectAllRef.current.checked = false;
                }
              }}
              ref={selectAllVisibleNotDownloadedExcelRef}
            />{' '}
            Select All Not Downloaded Excel on Page
          </div>
          <div style={{ margin: '0px 5px' }}>
            <input
              type="checkbox"
              onChange={event => {
                const currentPage = gridApi?.paginationGetCurrentPage() || 0;
                const pageSize = gridApi?.paginationGetPageSize() || 100;
                const startIndex = currentPage * pageSize;
                const endIndex = Math.min(
                  startIndex + pageSize - 1,
                  loans ? loans.length : 0,
                );

                const loansOnCurrentPage: OrderTrackingLoan[] = [];
                if (loans && loans.length > 0) {
                  gridApi?.forEachNodeAfterFilterAndSort(node => {
                    if (
                      node &&
                      node.rowIndex !== undefined &&
                      node.rowIndex !== null &&
                      node.rowIndex >= startIndex &&
                      node.rowIndex <= endIndex
                    ) {
                      for (let i = 0; i < loans?.length; i++) {
                        if (
                          node.data !== undefined &&
                          loans[i].loanId === node.data.loanId &&
                          !loans[i].lastDownloadedPdfDate
                        ) {
                          loansOnCurrentPage.push(loans[i]);
                        }
                      }
                    }
                  });
                }

                if (onSelectionChanged) {
                  onSelectionChanged(
                    loans && event.target.checked ? loansOnCurrentPage : [],
                    event.target.checked,
                  );
                }

                if (event.target.checked && selectAllRef.current) {
                  selectAllRef.current.checked = false;
                }
              }}
              ref={selectAllVisibleNotDownloadedPdfRef}
            />{' '}
            Select All Not Downloaded PDF on Page
          </div>
        </div>
        <BaseGrid<OrderTrackingLoan>
          ref={gridRef}
          data={data}
          idField="orderItemId"
          fields={allFields}
          onBodyGridReady={onGridReady}
          onChangeData={onChangeData}
          frameworkComponents={{
            BtnCellRenderer,
            CheckboxCellRenderer,
            EditableRenderer,
            StatusCellRederer,
          }}
          suppressPaginationPanel={false}
          suppressRowSelector
          checkboxSelection={false}
          // sizeColumnsToFit
          rowClassRules={{
            'highlight-row': (params: unknown) => {
              const { data: loanData } = params as { data: OrderTrackingLoan };
              if (highlightRows !== undefined) {
                return (
                  highlightRows.includes(loanData.loanId) &&
                  loanData.loanStatus === 'In Progress: Update requested'
                );
              }

              return loanData.loanStatus === 'In Progress: Update requested';
            },
          }}
        />
      </GridWrapper>
    </Container>
  );
}

export default BPOGrid;
